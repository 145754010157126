
<template>
    <div class="container">
      <div class="title">
        <span class="title-left"> <img src="@/assets/img/users/roles.png" alt="" style="margin-right:8px">
            Roles</span>
            <div class="btn_export flex_center"  @click="exportResult" ><img src="@/assets/img/search/export_ic1.png" />Export</div>
  
      </div>
      <div class="table">
        <div class="right_cont_box">
            <div class="table_box">
            <el-table ref="treeTable" :data="parentTablelist" border style="width: 100%">
              <!-- 空数据状态的插槽 -->
              <template slot="empty">
                  <noDate/>
              </template>
          
              <el-table-column label="Company Name"  prop="companyNameEn" width="450" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
              <template slot-scope="scope">
                <div  @click="setid1(scope.row)" exact class="tbl_company" v-show="!showTranslate"><img style="height: 26px;width: 36px;margin-right: 10px;" :src="scope.row.flag" alt="">{{scope.row.companyNameEn}}</div>
                <div  @click="setid1(scope.row)" exact class="tbl_company" v-show="showTranslate"><img style="height: 26px;width: 36px;margin-right: 10px;" :src="scope.row.flag" alt="">{{scope.row.companyName}}</div>
              </template>
              </el-table-column>
              <!-- showTranslate==true?'companyTypeCn':'companyTypeEn'/ -->
              <el-table-column label="Company Type" width="270" prop="companyTypeEn" align="left" show-overflow-tooltip :resizable="false" />
              <el-table-column label="Country" width="200" prop="coutry" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                <template slot-scope="scope">
                <span @click="gotocountry(scope.row)">{{ scope.row.coutry}}</span>
                </template>
                </el-table-column>
              <el-table-column label="Roles" width="228"  prop="roleEn" align="center" show-overflow-tooltip :resizable="false" />
            </el-table>
            <div class="page_i_box">
              <Page :totalFont=true :total=page.total @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
              <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum}} results</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="ecahrts-header"> <span class="title-left"> <img src="../../assets/img/company/echarts.png" alt=""
              style="margin-right:8px"> Charts</span>
  
          <div class="head_loging" :class="loginStatus">
            <el-switch v-model="loginStatus" @change="loginPop"  active-color="#13ce66"></el-switch>
  
          </div>
        </div>
        <div v-show="loginStatus" class="ecahrtstu"> 
      <div ref="trendChart" class="trendEchartBox1" />
      <div class="echartsBtns">
            <el-button icon="el-icon-plus" :disabled="level >= 3" @click="changeLevel(level + 1)" />
            <el-button icon="el-icon-minus" :disabled="level <= 1" @click="changeLevel(level - 1)" />
          </div>
    </div>
      </div>
  
          <!-- add to List -->
          <el-dialog :visible.sync="addDialog" center :close-on-click-modal='false' @close="closeAdd()" custom-class="dialog_normal" :append-to-body="true">
        <div class="detail_cont">
          <div class="dialog_detail_title">Add to My List</div>
          <div class="add_list">
            <div class="add_item" :class="index==i?'on':''" v-for="(item,i) in addList" :key="i" @click="addItem(i)">
              <div class="add_img"><img src="../../assets/img/search/add_img1.png"/></div>
              <div class="add_des">
                <div class="add_des_title">{{item.name}}</div>
                <div class="add_des_txt">{{item.num}} Collections</div>
              </div>
            </div>
          </div>
          <div class="btn_box flex_center_between_box">
            <button class="btn_export">Done</button>
            <button class="btn_create" @click="createNew">Create a new group</button>
          </div>
        </div>
      </el-dialog>

      <!-- create to List -->
      <el-dialog :visible.sync="createDialog" center :close-on-click-modal='false' custom-class="dialog_normal" :append-to-body="true">
        <div class="detail_cont">
          <div class="dialog_detail_title">Add to My List</div>
          <div class="create_item">
            <div class="create_item_lab flex_center_between_box">
              <span>Name</span>
              <span class="create_item_num">64</span>
            </div>
            <div>
              <input class="create_inpt" type="text" placeholder="Please enter group name"/>
            </div>
          </div>
          <div class="btn_box flex_center_between_box">
            <button class="btn_export">Done</button>
            <button class="btn_return" @click="createDialog=false;addDialog=true">return</button>
          </div>
        </div>
      </el-dialog>

      <!-- 点击导入 -->
 <el-dialog :visible.sync="exportDialog" center :close-on-click-modal="false" custom-class="large_dialog" :append-to-body="true">
      <div class="large_detail_cont">
        <div class="dialog_detail_title">Please select a company name</div>
        <div class="company_box">
          <div class="comp_top_check flex_center_between_box">
            <el-checkbox v-model="checkAll" @change="checkedAllCompany">Select All</el-checkbox>
            <div class="comp_search">
              <input class="coll_search_inpt" v-model="filterName" type="text" placeholder="Enter company name" />
              <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="hanldFilterName" />
            </div>
          </div>
          <div class="check_item" v-infinite-scroll="infiniteScroll" :infinite-scroll-disabled="routeLoad || noMore"
            :infinite-scroll-distance="5">
            <el-checkbox-group  v-model="checkedCompanyList">
              <el-checkbox v-for="company in companyList" :label="company" :key="company.aaa_id">{{
                company.companyNameEn
              }}</el-checkbox>
              
            </el-checkbox-group>
            <!-- <el-checkbox-group v-if="show==3" v-model="checkedCompanyList">
              <el-checkbox v-for="company in companyList" :label="company" :key="company.aaa_id">{{
                company.company_name_en
              }}</el-checkbox>
              
            </el-checkbox-group> -->
          </div>
        </div>
        <div class="company_b_txt">{{ checkedCompanyList.length }} enterprises were selected</div>
        <div class="btn_comp_box">
          <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_field" :header="title"
            name="company.xls">
            <button class="btn_export" @click="exportCompany">Export</button>
          </download-excel>
        </div>
      </div>
    </el-dialog>
    </div>
  </template>
  <script>
  import { getpersonnel } from '../../api/userApi';
  import noDate from "@/components/tableNoDate";
  import Page from "@/components/page";
  import { mixins1 } from '@/mixins/index'
  // import { data } from './data';
import { formatterTxt, formatterTxtTips,roleformatterTxtTips, roleformatterTxt,formatList } from './utils'
  export default {
    mixins: [mixins1],
    components: { noDate, Page },
    props:{
      showTranslate: {
      type: Boolean,
      default: false,
    },
  },
    data() {
      return {
        parentTablelist:[],
        companyAaaid:'156107587326',
        level :2,
        comIcon1: 'image://' + require('@/assets/img/echarts/comIcon1.png'),
      comIcon2: 'image://' + require('@/assets/img/echarts/comIcon2.png'),
      comIcon3: 'image://' + require('@/assets/img/echarts/comIcon3.png'),
      comIcon4: 'image://' + require('@/assets/img/echarts/comIcon4.png'),
      comIcon5: 'image://' + require('@/assets/img/echarts/comIcon5.png'),
        loginStatus: false,//登录状态
        pageChild: {
          pageNo: 1,
          pageSize: 5,
          total: 0
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0
        },
        icon: '',
        // 表
        parentTable: [{ id: '1', CompanyName: 'Apple Inc.', Country: 'CHN', Type: 'Domestic Companies',  Role: 'VIP' }, 
        
        { id: '2', CompanyName: 'Apple Inc.', Country: 'USA', Type: 'Domestic Companies',  Role: 'VIP' }],
        childTable:[
        {CompanyName:'Apple Inc.',Location:'California',Industry:'Computer',IncYr:'1977',Status:'Active',isActive:'Active'}
      ],
      addDialog:false,//添加列表弹框
      index:-1,
      addList:[{name:'Client',num:'2'},{name:'Supplier',num:'6'},{name:'Partner Interest',num:'18'},{name:'Competitor',num:'9'}],
      createDialog:false,//新增分组弹框
      createNewName:'',

      exportDialog:false,
      noMore: false, // 控制滚动禁用
      routeLoad: false, // 控制滚动禁用
      filterName: '',
      checkedCompanyList:[],
      companyList:[],

        fromPage: 'partner',
        checkAll: false,
        title: 'Partner',
        json_field: {
        'Company Name': 'companyNameEn',
        'Company Type': 'companyTypeEn',
        'Country': 'countryCode',
        'Roles': 'roleEn',
        
      },
      DetailsForm: [],
      }
      
    },
    computed: {
      openMenu: {
        get() {
          return this.menuTitleList.map((i) => {
            return i.title;
          })
        },
        set() { }
  
      }
    },
    watch: {
  
      $route: {
        handler(val, oldval) {
          this.Indexy = val.path.replace('/', '')//当前活动路由
        },
        // 深度观察监听
        deep: true
      }
    },
    mounted() {
      this.huoqugetpersonner()
      // this.echarts()
    },

    methods: {
      pageList() {
      console.log(this.parentTable);
   
      this.parentTablelist = this.parentTable.filter(
        (item, index) =>

            index < this.page.pageNo * this.page.pageSize &&
          index >= this.page.pageSize * (this.page.pageNo - 1)
      );
      this.page.total= this.parentTable.length
    },

      onPageChangeList(pageNo) {
        // this.pageNo += 1 
        // console.log(pageNo,'pageno');
         this.page.pageNo = pageNo
         this.pageList()
      },
      currtentPageChange(pageSize) {
        // console.log(pageSize,'pageSize');
        // this.pageNo += 1 
      this.page.pageSize = pageSize
      // this.init()
      this.pageList()
    },
      exportResult() {
        // console.log(111111);
      this.pageNo = 1
      this.pageSize = 20
      // this.getRouteList()
      this.exportDialog = true
      this.huoqugetpersonner()
    },

        //模糊查询
        hanldFilterName() {
      let list = this.companyList
      let keyWord = this.filterName
      var arr = []
      if (keyWord == '') {
        return this.companyList
      } else {
        arr = list.filter((item) => {
          return item.company_name_en.includes(keyWord)
        })
      }
      this.companyList = arr
    },
           //导出
           async exportCompany() {
      // console.log(this.checkedCompanyList);
   
        this.DetailsForm = this.checkedCompanyList
  
    
    },
    // 滚动加载方法
    infiniteScroll() {
      this.routeLoad = true
      this.pageNo += 1 // 页码每次滚动+1
      // this.getRouteList()
      this.huoqugetpersonner()
    },
      setid1(row) {
      sessionStorage.setItem('company', JSON.stringify(row))
      this.$router.push({
        path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.countryCode,
      })
    },
           // 跳转国家
           gotocountry(row){
            let routeData= this.$router.resolve({
                path: '/country?'  + '&companyCountry=' + row.countryCode,
            });
      
            window.open(routeData.href, '_blank');
        },
      async huoqugetpersonner() {
        const pid = this.$route.query.pid
      // const name=this.$route.query.name
        const params={
            // page:1,
            // pageSize:10,
            // roleList:'',
            // name:name,
            pid:pid,
            // industryCodeList:"",
            // companyStatusCodeList:""
        }
            const res = await getpersonnel(params)
            if (res && res.code) {
              let ecahrtsdata =res.data.rows
              this.echartsdate = ecahrtsdata
              this.echartsdate = JSON.parse(JSON.stringify(this.echartsdate[0]).replace(/companyInfoList/g, 'children'))
      this.echartsdate.children = JSON.parse(JSON.stringify(this.echartsdate.children).replace(/companyNameEn/g, 'pnameEn'))
              this.$nextTick(() => {
        this.echarts()
      })
      // console.log(this.echartsdate)
        // this.page.total = reslist.data.total
        let list = (  this.parentTable=res.data.rows[0].companyInfoList)
     
        //  console.log(this.parentTable);
        //查找本地图片
        list = list.map((item, index) => {
          let countryImg = this.getCountryImgUrl(item.countryCode)
          let coutry =this.getCountry(item.countryCode)
          let flag = require('../../../static' + countryImg)
          this.parentTable[index].flag = flag
          this.parentTable[index].coutry=coutry
          this.refresh++
        })
        this.pageList()
        if(  this.exportDialog ){
          this.companyList=[]
          let list = this.parentTable;
              
              for (let i = 0; i < list.length; i++) {
                  this.companyList.push(list[i]);
              }
              this.companyTblList = this.companyList;
              if (list.length < 10) {
                  this.noMore = true;
              }
              if (this.companyList.length === res.data.total) {
                  this.noMore = true;
              }
              this.routeLoad = false;
              console.log(this.companyList);
        }
      }
      else {
        this.parentTable = []
      }
              //  this.parentTable=res.data.rows[0].companyInfoList
        },
        changeLevel (newLel) {
      this.level = newLel
      this.$nextTick(() => {
          this.echarts()
        })
      // this.treeChart(this.echartData, newLel)
    },
        echarts() {

      const that = this
     
    //  const initialTreeDepth=2
     let leval=that.level
const initialTreeDepth = leval
     let data = that.echartsdate
      const myChart = this.$echarts.init(this.$refs.trendChart)

      myChart.showLoading()
  myChart.hideLoading();

  const symbol = (data) => {
        if (`${data.invtypecode}` === '3') {
        // 外国公司
          return this.comIcon3
        }
        if (`${data.invtypecode}` === '5') {
        // 国营企业
          return this.comIcon5
        }
        if (data.aaa_id === this.companyAaaid) {
          // 主体
          return this.comIcon4
        }
        if (!data.aaaId) {
          // 个人
          return this.comIcon2
        }
        return this.comIcon1
      }
    const option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          fontSize: 12,
          formatter: (params) => roleformatterTxtTips(params
          )
        },
        series: [
          {
            type: 'tree',
            data: [data],
            links:[    {
        source: 'companyName.',
        target: 'pnameEn',
    
        label: {
          show: true
        },
        relation:{
            name:"法人",
            id:'1'
        },
        lineStyle: {
          width: 5,
          color:'red',
          curveness: 0.2
        }
      }],
            top: '18%',
            bottom: '14%',
            // roam: true,
            layout: 'radial',
            symbol: (value, param) => symbol(param.data),
            symbolSize: 30,
            initialTreeDepth,
            animationDurationUpdate: 750,
            emphasis: {
              focus: 'ancestor'
            },
            orient: 'TB',
            label: {
              fontSize: 9,
              formatter: (params) => roleformatterTxt(params),
              rotate: 360
            },
            leaves: {
              label: {
                fontSize: 9,
                formatter: (params) => roleformatterTxt(params),
                rotate: 360
              }
            }
          }
        ]
      }
  myChart.setOption(option);

      // myChart.resize()
    },
    
        //分页


  //添加列表
  addToList(obj){
    this.addDialog=true
  },
  //新增分组
  createNew(){
    this.addDialog=false
    this.createDialog=true
  },
  //点击弹窗分组
  addItem(i){
    this.index=i
  },
  //关闭add弹窗
  closeAdd(){
    this.index=-1
  },

  //弹窗全选公司
  checkedAllCompany(val){
    this.checkedCompanyList = val ? this.companyList : [];
  },


  //展开当前关闭其他展开表格
  onClickExpansion(row) {
      this.treeTable.map((item) => {
      if (row.id != item.id) {
          item.isExpand = false;
          this.$refs.treeTable.toggleRowExpansion(item, false);
          }
      });
      row.isExpand = !row.isExpand;
      this.$refs.treeTable.toggleRowExpansion(row);
      if(row.isExpand){
          this.childTable=[]
          //this.getAssetCirList(row.id)
      }
  },
  handleChange(val) {
        this.icon = val
        // this.iconlist=val
        // this.iconlist.some(item=>{
        //     console.log(item);
        // })
      },
      loginPop() {

        this.echarts()
      },
    },
  
  }
  </script>
  <style scoped lang="less">
.echartsBtns {
  position: absolute;
  top: 30px;
  left: 30px;
}
.echartsBtns ::v-deep button {
  padding: 4px 15px !important;
  font-size: 15px;
  background-color: transparent;
}
  .cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
  }
/deep/ .el-table th.is-leaf{
// padding-left: 30px;
  }

  .Company Snapshot {}
  
  .Country Data {}
  .company_link .cell a{
    display: flex;
    align-items: center;
    img{
      margin-right: 20px;
    }
  }
  .title,
  .ecahrts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 69px;
  
    .title-left {
      display: flex;
      font-size: 16px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #022955;
    }
  }
  
  .footer {
    margin-top: 20px;
    padding:  20px;

  overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
  }
  .ecahrts-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-left{
      display: flex;
    }
  }
  .table {
    margin-top: 20px;
  }
  
  .trendEchartBox1 {
    width: 640px;
    height: 524px;
  }
  
  .ecahrtstu {
  display: flex;
  justify-content: center;
    width: 980px;
  position: relative;
    background: #FFFFFF;
  
  
  }
 /deep/ .el-table__expanded-cell[class*=cell] {
    padding: 20px 20px;
  }
//   心动弹窗
.add_list{
margin-bottom: 20px;
}
.add_item{
display: flex;
padding: 10px 9px 8px;
margin-bottom: 10px;
background: #FFFFFF;
border-radius: 12px;
border: 1px solid #E7EAED;
}
.add_item:hover{
background: #E7EAED;
}
.add_item.on{
background: #1290C9;
}
.add_item.on .add_des_txt,
.add_item.on .add_des_title{
color: #FFFFFF;
}
.add_img{
height: 42px;
}
.add_img img{
width: 38px;
height: 42px;
margin-right: 9px;
}
.add_des_title{
font-size: 16px;
font-family: "Arial Bold";
color: #022955;
line-height: 20px;
}
.add_des_txt{
font-size: 12px;
color: #8497AB;
line-height: 16px; 
}
.btn_return,
.btn_create{
width: 180px;
height: 40px;
line-height: 40px;
background: #E7EAED;
border-radius: 12px;
font-size: 16px;
color: #022955;
}
.btn_create{
width: 180px;
}
.btn_return{
width: 140px;
}
.create_item_lab{
font-size: 16px;
font-family: "Arial Bold";
color: #022955;
margin-bottom: 8px;
}
.create_item_num{
font-size: 14px;
font-family: "Arial Bold";
color: #8497AB;
}
.create_inpt{
width: 100%;
height: 46px;
background: #FFFFFF;
border-radius: 12px;
border: 1px solid #E7EAED;
font-size: 16px;
padding: 13px 20px;
margin-bottom: 20px;
}
.btn_comp_box{
text-align: right;
}
.check_item{
position: relative;
max-height: 540px;
overflow: auto;
background: #F2F5F9;
border-radius: 8px;
border: 1px solid #E7EAED;
padding: 20px 20px 0;
margin-bottom: 10px;
}
.check_item::after{
content: '';
position: absolute;
left: 48%;
top:20px;
bottom: 20px;
width: 2px;
background-color: #E7EAED;
}
.table_box /deep/ .el-table th>.cell:first-child {
    // padding-left: 12px;
}
.company_box .el-checkbox {
  width: 50%;
  margin-bottom: 20px;
  margin-right: 0;
}

.company_box .comp_top_check .el-checkbox {
  margin-bottom: 10px;
  margin-left: 20px;
}

.company_box /deep/ .el-checkbox__label {
  color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #022955;
  border-color: #022955;
}

.company_box /deep/ .el-checkbox__inner {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #8497ab;
}

.company_box /deep/ .el-checkbox__inner::after {
  height: 10px;
  left: 6px;
  top: 1px;
  width: 5px;
  border-width: 2px;
}

.comp_search {
  position: relative;
  width: 300px;
  margin-bottom: 10px;
}

.comp_search .coll_search_btn {
  top: 7px;
}
.company_b_txt {
  font-size: 12px;
  color: #8497ab;
  line-height: 16px;
}

.btn_export_left {
  width: 30px;
  height: 30px;
  background: #1290c9;
  border-radius: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_item_lab {
  font-size: 16px;
  font-family: 'Arial Bold';
  color: #022955;
  margin-bottom: 8px;
}

.create_item_num {
  font-size: 14px;
  font-family: 'Arial Bold';
  color: #8497ab;
}

.create_inpt {
  width: 100%;
  height: 46px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e7eaed;
  font-size: 16px;
  padding: 13px 20px;
  margin-bottom: 20px;
}

.btn_comp_box {
  text-align: right;
}

.check_item {
  position: relative;
  max-height: 540px;
  overflow: auto;
  background: #f2f5f9;
  border-radius: 8px;
  border: 1px solid #e7eaed;
  padding: 20px 20px 0;
  margin-bottom: 10px;
}

.check_item::after {
  content: '';
  position: absolute;
  left: 48%;
  top: 20px;
  bottom: 20px;
  width: 2px;
  background-color: #e7eaed;
}

  </style>